import { Typography } from '@material-ui/core';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'

import ReactPlayer from 'react-player'

import Rockerz from './img/nft.gif'
import RockerzMen from './img/rockerz.png'
import Band from './img/band-2.png'

import Icon1 from './img/0.gif'
import Icon2 from './img/1.gif'
import Icon3 from './img/2.png'
import Icon4 from './img/6.png'
import Icon5 from './img/18.gif'
import Icon6 from './img/13.png'
import Icon7 from './img/16.png'
import Icon8 from './img/60.gif'
import Icon9 from './img/27.png'

import Guy1 from './img/Guy1.png'
import Guy2 from './img/Guy2.png'
import Guy3 from './img/Guy3.png'
import Guy4 from './img/Guy4.png'

const timeline = [
  {
    date: 'Q1 2022',
    title: 'Sale!',
    content: 'Sale of Rockerz Guitarist Series.',
    icon: Icon1,
  },
  {
    date: 'Q1 2022',
    content: 'Initial launch of the game',
    icon: Icon4
  },
  {
    date: 'Q1 2022',
    content: '$ROCKR token launch',
    icon: Icon5
  },
  {
    date: 'Q2 2022',
    content: 'Marketplace launch',
    icon: Icon6
  },
  {
    date: 'Q2 2022',
    content: 'Guitarist series holders will mint for half price',
    icon: Icon3,
    title: 'Minting of Drummers, Bassists and Keyboardists',
  },
  {
    date: 'Q3 2022',
    content: 'MuVenues minting',
    icon: Icon7
  },
  {
    date: 'Q3 2022',
    content: 'MuVerse Launch - Multiplayer world',
    icon: Icon8
  },
  {
    date: 'Q4 2022',
    content: 'Composing platform',
    icon: Icon9
  }
]

function Roadmap() {
  return <>
    <p style={{paddingTop: 50, paddingBottom: 50}}>
    <ReactPlayer id="bg-video" playing loop playsinline muted url='rockerz-demo.mp4' width='100%' height={500} />
    </p>

    
    <div style={{maxWidth: 1200, margin: '0 auto'}}>
      <Typography variant="h2">
        <span className="rainbow">EXPLORE. FIGHT. PERFORM. EARN.</span>
      </Typography>
      <p style={{textAlign: 'left'}}>
      <img src={Guy2} alt="" className="align-right" height="200" />
      Explore the mind-opening, open-world MMO filled with adventures, battles and opportunities on the Metaverse of Muland. Where you’ll be writing your own destiny as a musician. Never ending opportunities to earn, fight and play waiting for you among the wilderness of Muland. Put a band together or survive alone, real-time combat system, PvE and PvP battles, real time concerts and more, the choice is yours.
      </p>
      
      <Typography variant="h3">Muland</Typography>
      <p style={{textAlign: 'left'}}>
      Muland is the land where humanity all began. It's the place where lots of people from different races had been living for hundreds of thousands of years together. Everything had been so ordinary for a long long time, but then people started to evolve. 
      </p>

      <p style={{textAlign: 'left'}}>
      <img src={Guy1} alt="" className="align-left" height="200" />
      They came up with languages, discoveries, inventions. Some were open to new ideas but some were not. This resulted to the first fight in humanity. By time people multiplied in population rapidly, fights turned into wars which led to separations. For the first time in history, there were multiple groups of people living completely separated from each other. But it didn't end up with 2 groups. 
      </p>

      <p style={{textAlign: 'left'}}>
      The wars kept continuing until a very long winter began to occur. People from all over Muland were fighting just to be able stay alive. Unfortunately, most of them couldn't. 
      </p>

      <p style={{textAlign: 'left'}}>
      <img src={Guy4} alt="" className="align-right" height="200" />
      The weather didn't get warm for a very long time. People died from hunger, sickness, drought, famine and lots of other reasons.
      </p>

      <p style={{textAlign: 'left'}}>
      <img src={Guy3} alt="" className="align-left" height="200" />
      Thousands of years later a sound was heard and weather started to warm up again, but now Muland was a very different place. People who survived in different parts of Muland adapted to their lands and changed. This never ending winter created people of 7 different races. Snow, Forest, Water, Undead, Human, Vampire and Fire. Some were shorter, some were stronger, some were smarter... but there was one thing in common, which was the sound they heard before the long winter ended. They thought it was the sign for good things to happen. Then the groups interpreted the sound trying to replicate it in their own different ways and believed that it would bring good luck to them. That ended up creating the first music and genres. They built their first instruments along the way.
      </p>
    </div>


    <hr />

    <p>
    <img className="nft-gif" src={Rockerz} alt="Rockerz NFT" />
    </p>

    <Typography variant="h2">9999 uniquely generated Rockerz are created using 100+ traits and 200+ songs</Typography>
    
    <hr />
    <Typography variant="h2"><span className="rainbow">Rockerz are your game avatars</span></Typography>
    <p>
        You may want to own Rockerz just for the art but it still comes with some perks!
        Rockerz are your avatars in the game, which takes place in <mark>MuLand</mark>. In this MMORPG style game,
        you will develop your character by finishing challenges, earn <mark>$ROCKR</mark> tokens&NFTs(instruments, accessories etc) while playing
        and <u>stake</u> your tokens. Once you stake enough tokens, you will have the right to join the multiplayer <mark>MetaVerse(MuVerse)</mark>.
    </p>
    
    <hr />
    <Typography variant="h2">Marketplace</Typography>
    <p>
      This is where you buy or sell your <u>accessories</u>, <u>instruments</u> and all <u>NFTs</u> that are used in MuLand and <mark>MuVerse</mark>.
    </p>
    <hr />
    <Typography variant="h2">$ROCKR token</Typography>
    <p>
      <mark>$ROCKR</mark> is a <u>Solana</u> token which is used as the currency in <mark>MuLand</mark> and <span className="rainbow">MuVerse</span>. You will be able to earn, stake and shop in the marketplaces.
    </p>
    <hr />
    <Typography variant="h2">Composing platform</Typography>
    <p>
      The Rockerz NFT owners will have access to our music composing platform
      with a library of <span className="rainbow">thousands of music tracks</span>.
      You will be able to make your music on the platform,
      own it and share with the world!
    </p>
    <hr />
    <Typography variant="h2">Bands</Typography>
    <p>
      You will be able to form bands by collecting diffrent series. Bands are <span className="rainbow">DAOs</span>.
    </p>

    <img src={Band} alt="band" className="nft-gif" />

    <hr className="normal" />
    <Typography variant="h2">
      <a id="roadmap">Roadmap</a>
    </Typography>

    <p>
      <VerticalTimeline animate={false}>
        {timeline.map((item) => (
          <VerticalTimelineElement
            date={item.date}
            iconStyle={{ background: '#fff' }}
            icon={<img className="icon" src={item.icon} alt="" />}
          >
            {item.title ? <h4>{item.title}</h4> : ''}
            <div>{item.content}</div>
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </p>

    <hr />
    <Typography variant="h2">Follow us on Social Media</Typography>
    <p>
      <a href="https://twitter.com/rockerznft" target="_blank">Twitter</a> &bull; <a href="https://instagram.com/rockerznft" target="_blank">Instagram</a> &bull; <a href="https://discord.gg/rockerznft" target="_blank">Discord</a>
    </p>
    <img src={RockerzMen} alt="Rockerz Men" style={{maxWidth: '100%'}} />
    <p>
    <small>Rockerz NFT Team</small>
    </p>
  </>
}

export default Roadmap